let lastKnownScrollPosition = 0;
let ticking = false;

function animateNavigation(scrollPos, up) {
  var header = document.getElementById('buchhandel-header');
  
  if(up || scrollPos < 10){
    header.classList.remove('reduced')
  }else if(scrollPos >= 10){
    header.classList.add('reduced')
  }
}

$(document).ready(function(){
  
  var header = document.getElementById('buchhandel-header');

  if(header){
    document.addEventListener('scroll', (e) => {
      up = window.scrollY < lastKnownScrollPosition;
      lastKnownScrollPosition = window.scrollY;
    
      if (!ticking) {
        window.requestAnimationFrame(() => {
          animateNavigation(lastKnownScrollPosition, up);
          ticking = false;
        });
    
        ticking = true;
      }
    });
  }
})