'use strict';

require('./head.js');

import stickybits from 'stickybits';

var ImagesLoaded = require('imagesloaded');
var jQueryBridget = require('jquery-bridget');
var tablesorter = require('tablesorter');
var Masonry = require('masonry-layout');
var TableExport = require('tableexport');

TableExport.prototype.defaultFilename = $(document)
  .find('title')
  .text();

jQueryBridget('masonry', Masonry, $);
jQueryBridget('imagesLoaded', ImagesLoaded, $);

$(document).ready(function () {
  if ($("#votearea").length) {

    var firstMutation = false;

    $("#votearea").find(".btn").prop("disabled", true).addClass('cursor-auto')
    
    var refreshId = setInterval(function () {
      if (window.upvote.setupComplete) {
        setTimeout(()=>{
          var alreadyVoted = $("#votearea").find('.upvote-vote-match').length;
          if(!alreadyVoted){
            $("#votearea").find(".btn").prop("disabled", false).removeClass('cursor-auto')
          }
        },2000)
        clearInterval(refreshId);
      }
    }, 500);

    $("#votearea").find(".btn").click(function () {
      var checkMutation = $('.upvote-vote-match');
      if (checkMutation) {
        firstMutation = true;
      }
      if (window.upvote.setupComplete) {
        $("#votearea").find(".btn").prop("disabled", true).addClass('cursor-auto')
      }
    })

    const targetNode = document.querySelectorAll('.upvote-el');
    const config = {
      attributes: true,
      childList: true,
      subtree: true
    };

    const callback = function (mutationsList, observer) {
      if (mutationsList[0].attributeName == 'class') {
        if (firstMutation) {
          $("#votemsg").css("display", "block")
          setTimeout(() => {
            $("#votemsg").fadeOut()
          }, 5000)
        } else {
          firstMutation = true;
        }
      }
    };

    const observer = new MutationObserver(callback);
    targetNode.forEach(function (el) {
      observer.observe(el, config);
    })
  }
});

$('#hilfe-content')
  .find('table')
  .each(function() {
    var instance = new TableExport($(this), {
      formats: ['xlsx', 'csv'],
      exportButtons: false,
      filename: 'Data export'
    });

    // fix for tables in rich text
    if ($(this).attr('id') == undefined) {
      var pseudo_id = $(this).attr('tableexport-key');
      var button_id = pseudo_id + 'button';
      $(this).attr('id', pseudo_id);

      $(
        '<button class="d-none btn btn-secondary btn-xls-export" id=\'' +
          button_id +
          "'>Export als xlsx</button>"
      ).insertAfter($(this).closest('.redactor-content'));

      $(
        '<button class="d-none btn btn-secondary btn-xls-export" id=\'' +
          button_id +
          '-csv' +
          "'>Export als csv</button>"
      ).insertAfter($(this).closest('.redactor-content'));
    }

    var exportData = instance.getExportData()[$(this).attr('id')]['xlsx'];
    var XLXbutton = document.getElementById($(this).attr('id') + 'button');

    var exportDataCSV = instance.getExportData()[$(this).attr('id')]['csv'];
    var CSVbutton = document.getElementById($(this).attr('id') + 'button-csv');

    CSVbutton.addEventListener('click', function(e) {
      instance.export2file(
        exportDataCSV.data,
        exportDataCSV.mimeType,
        exportDataCSV.filename,
        exportDataCSV.fileExtension
      );
    });

    XLXbutton.addEventListener('click', function(e) {
      instance.export2file(
        exportData.data,
        exportData.mimeType,
        exportData.filename,
        exportData.fileExtension
      );
    });
  });

// Vars for Login Box
// Base URL that is used in several links. (No trailing slash!)
var host = 'vlb.de';
if (
  typeof window.location.hostname !== undefined &&
  window.location.hostname != ''
)
  host = window.location.hostname;
var baseUrl = 'https://' + host + '/app';

// Extracts URL parameters
function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = value;
  });
  return vars;
}

$(document).ready(function() {

  $('#events-table').tablesorter({
    theme: 'blue',
    headerTemplate: '{content}{icon}',
    sortList: [[1,0]],
    widgets: [],
    widgetOptions : {
      // filter_anyMatch replaced! Instead use the filter_external option
      // Set to use a jQuery selector (or jQuery object) pointing to the
      // external filter (column specific or any match)
      filter_external : '.search',
      // add a default type search to the first name column
      filter_defaultFilter: { 1 : '~{query}' },
      // include column filters
      filter_columnFilters: true,
      filter_placeholder: { search : 'Search...' },
      filter_saveFilters : true,
      filter_reset: '.reset'
    }
    // widgets: ['uitheme'] <- needed for jQuery UI & Bootstrap
  });
  // Sticky top IE11 Fix
  stickybits('.sticky-top');

  if (
    (navigator.appName == 'Microsoft Internet Explorer' ||
      !!(
        navigator.userAgent.match(/Trident/) ||
        navigator.userAgent.match(/rv:11/)
      ) ||
      (typeof $.browser !== 'undefined' && $.browser.msie == 1)) &&
    $('.sticky-top').length !== 0
  ) {
    $('.sticky-top').css('max-width', $('.sticky-top').width());
  }

  // Owl Carousels
  $('.owl-one').imagesLoaded(function() {
    $('.owl-one').owlCarousel({
      items: 12,
      loop: true,
      margin: 0,
      merge: true,
      nav: true,
      dots: true,
      navText: ['', ''],
      addClassActive: true,
      autoplay: 6000,
      autoplayTimeout: 6000,
      autoplaySpeed: 2000,
      autoplayHoverPause: false,
      responsive: {
        0: {
          merge: false,
          items: 1
        },
        678: {
          mergeFit: true
        },
        1000: {
          mergeFit: false
        }
      },
      // disable all owl interaction if carousel only has one item
      onInitialized: function(event) {
        if (event.item.count <= 1) {
          this.options.loop = false;
          this.options.dots = false;
          this.options.mouseDrag = false;
          this.options.touchDrag = false;
          this.options.pullDrag = false;
          this.options.freeDrag = false;
          this.options.grabClass = null;
        }
        this.refresh();
      }
    });
  });

  $('.owl-two').owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    navText: ['', ''],
    addClassActive: true,
    center: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 3
      }
    }
  });

  $('.owl-three').imagesLoaded(function() {
    $('.owl-three').owlCarousel({
      items: 1,
      loop: true,
      navText: [
        "<i class='fal fa-arrow-left'></i>",
        "<i class='fal fa-arrow-right'></i>"
      ],
      smartSpeed: 900,
      singleItem: true,
      autoplay: true,
      autoplayTimeout: 7000,
      autoplayHoverPause: true,
      responsive: {
        0: {
          dots: true,
          nav: true,
          stagePadding: 0,
          margin: 30,
        },
        768: {
          dots: false,
          nav: true,
          stagePadding: 0,
          margin: 30
        }
      }
    });
  });

  $('.owl-three-single').imagesLoaded(function() {
    $('.owl-three-single').owlCarousel({
      items: 1,
      loop: false,
      dots: false,
      nav: false,
      singleItem: true
    });
  });

  $('.intro-owl-carousel').owlCarousel({
    singleItem: true,
    nav: false,
    dots: true,
    items: 1,
    itemsDesktop: false,
    itemsDesktopSmall: false,
    itemsTablet: false,
    itemsMobile: false
  });

  setTimeout(function() {
    if ($('div.owl-item:not(.cloned)').length == 1) {
      $('div.owl-dots button').hide();
    }
  }, 10);

  // Masonry Layout
  var $grid = $('.grid');
  $grid.imagesLoaded(function() {
    $grid.masonry({
      itemSelector: '.grid-item',
      columnWidth: '.grid-item',
      gutter: 20,
      horizontalOrder: true
    });
  });

  $('img.lazy').lazyload();

  // Accordion
  $('.accordion .card-header').on('click', function() {
    $(this)
      .parent()
      .parent()
      .find('.card-header.active')
      .not(this)
      .removeClass('active');
    $(this).toggleClass('active');
  });

  // Table Overlay
  $('.content-support table')
    .parent('div')
    .before(
      '<div class="expand" data-toggle="modal" data-target="#tableModal"><i class="fal fa-expand-arrows"></i></div>'
    );
  $('.expand').on('click', function() {
    $('#tableModal .content').html(
      $(this)
        .parent()
        .find('table')
        .clone()
    );
  });

  // Cloning modal content
  /*
  $('div.table-responsive.expandable').each(function () {
    let $content = $(this).find('table.table')[0].outerHTML;
    $(this).find('.content').html($content);
  });
  */

  function fix_visibility() {
    $('.nav-ul-depth-2, .nav-ul-depth-3, .nav-ul-depth-4 ').hide();

    $('.marked.nav-ul-depth-1')
      .children('.active.nav-li-depth-1')
      .children('.nav-ul-depth-2')
      .show();
    $('.marked.nav-ul-depth-1')
      .children('.active.nav-li-depth-1')
      .children('.nav-ul-depth-2')
      .siblings('div')
      .children('.parent-marker')
      .html('-');

    if ($('.marked.nav-ul-depth-2')[0]) {
      $('.marked.nav-ul-depth-2').show();

      $('.marked.nav-ul-depth-2')
        .children('.active.nav-li-depth-2')
        .children('.nav-ul-depth-3')
        .show();
      $('.marked.nav-ul-depth-2')
        .children('.active.nav-li-depth-2')
        .children('.nav-ul-depth-3')
        .siblings('div')
        .children('.parent-marker')
        .html('-');

      $('.marked.nav-ul-depth-2')
        .siblings('div')
        .children('.parent-marker')
        .html('-');
    } else if ($('.marked.nav-ul-depth-3')[0]) {
      $('.marked.nav-ul-depth-3')
        .closest('ul.nav-ul-depth-2')
        .show();
      $('.marked.nav-ul-depth-3').show();

      $('.marked.nav-ul-depth-3')
        .siblings('div')
        .children('.parent-marker')
        .html('-');
      $('.marked.nav-ul-depth-3')
        .closest('ul.nav-ul-depth-2')
        .siblings('div')
        .children('.parent-marker')
        .html('-');

      $('.marked.nav-ul-depth-3')
        .children('.active.nav-li-depth-3')
        .children('.nav-ul-depth-4')
        .show();
      $('.marked.nav-ul-depth-3')
        .children('.active.nav-li-depth-3')
        .children('.nav-ul-depth-4')
        .siblings('div')
        .children('.parent-marker')
        .html('-');
    } else if ($('.marked.nav-ul-depth-4')[0]) {
      $('.marked.nav-ul-depth-4')
        .closest('ul.nav-ul-depth-2')
        .show();
      $('.marked.nav-ul-depth-4')
        .closest('ul.nav-ul-depth-3')
        .show();
      $('.marked.nav-ul-depth-4').show();

      $('.marked.nav-ul-depth-4')
        .siblings('div')
        .children('.parent-marker')
        .html('-');
      $('.marked.nav-ul-depth-4')
        .closest('ul.nav-ul-depth-3')
        .siblings('div')
        .children('.parent-marker')
        .html('-');
      $('.marked.nav-ul-depth-4')
        .closest('ul.nav-ul-depth-2')
        .siblings('div')
        .children('.parent-marker')
        .html('-');
    }
  }

  fix_visibility();

  $('.parent-marker-1').click(function() {
    let $parent = $(this)
      .parent()
      .parent();
    $parent.find('ul.nav-ul-depth-2').slideToggle();
    if ($(this).html() === '+') {
      $(this).html('-');
    } else {
      $(this).html('+');
    }
  });
  $('.parent-marker-2').click(function() {
    let $parent = $(this)
      .parent()
      .parent();
    $parent.find('ul.nav-ul-depth-3').slideToggle();
    if ($(this).html() === '+') {
      $(this).html('-');
    } else {
      $(this).html('+');
    }
  });
  $('.parent-marker-3').click(function() {
    let $parent = $(this)
      .parent()
      .parent();
    $parent.find('ul.nav-ul-depth-4').slideToggle();
    if ($(this).html() === '+') {
      $(this).html('-');
    } else {
      $(this).html('+');
    }
  });

  // Search and display results
  $('#search-hilfe').keyup(function(e) {
    let filter = $(this).val();
    $('.parent-marker').hide();

    $(
      '.nav-li-depth-1, .nav-li-depth-2, .nav-li-depth-3, .nav-li-depth-4 '
    ).each(function() {
      if (filter === '') {
        $(this)
          .css('visibility', 'visible')
          .fadeIn();
      } else if (
        $(this)
          .text()
          .search(new RegExp(filter, 'i')) < 0
      ) {
        $(this)
          .css('visibility', 'hidden')
          .fadeOut();
      } else {
        $(this)
          .css('visibility', 'visible')
          .fadeIn();

        $(this)
          .parent()
          .css('visibility', 'visible')
          .fadeIn();

        $(this)
          .parent()
          .parent()
          .css('visibility', 'visible')
          .fadeIn();

        $(this)
          .parent()
          .parent()
          .parent()
          .css('visibility', 'visible')
          .fadeIn();
        if (
          $(this)
            .parent()
            .parent()
            .parent()
            .find('.parent-marker').length
        ) {
          $(this)
            .parent()
            .parent()
            .parent()
            .find('.parent-marker')
            .html('-');
        } else {
          $(this)
            .parent()
            .parent()
            .parent()
            .find('.parent-marker')
            .html('+');
        }
      }
    });

    if (filter === '' || filter == ' ') {
      $('.parent-marker').show();
      $('.parent-marker').html('+');
      fix_visibility();
    }
  });

  // Mobile Toggle Support Nav

  $('body').click(function(evt) {
    if (evt.target.id == 'show-sidenav') {
      evt.preventDefault;
      $('nav.side-navigation').toggleClass('open');
      return;
    }
    if (!$(evt.target).closest('nav.side-navigation.open').length)
      $('nav.side-navigation').removeClass('open');
    //Do processing of click event here for every element except with id menu_content
  });

  $("#my-form").submit(function(ev) {
    ev.preventDefault();
    $(".remove-this-on-form-submit").remove(); 
    var $self = $(this);
    console.log("1");  
    $.post({
      url: '/',
      dataType: 'json',
      data: $(this).serialize(),
      success: function(response) {
        if (response.success) {
          $self[0].reset();

          var $successMessage = $("<div>", {"class": "alert alert-info"})
              .append("<p><i class='fas fa-check'></i>&nbsp;&nbsp;Vielen Dank! Formular erfolgreich abgeschickt.</p>", {"class": "alert alert-success"});
          $self.after($successMessage);
        } else {
          alert("An error occurred. Please try again.");
        }
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        $self[0].reset();
        var $message = $("<div>", {"class": "alert alert-danger remove-this-on-form-submit"})
            .append('<p>'+XMLHttpRequest.responseText+'</p>', {"class": "alert alert-danger"});
        $self.after($message);
      }
  });
  });

  $('form:not(#search-form-press)').on("freeform-render-form-errors",function(event){  
    event.preventDefault();
    var $self = $(this);
    var theLanguage = $('html').attr('lang');
    $('.alert-success-form-submit').remove();
    $('.remove-this-on-form-submit').remove();

    if (theLanguage == 'de') {
      var $successMessage = $('<div>', {
        class:
          'alert alert-danger remove-this-on-form-submit alert-danger-form-submit',
        id:
          'formErrors',  
      }).append(
        "<p><i class='fas fa-times'></i>&nbsp;&nbsp;Bitte füllen Sie alle Pflichfelder aus</p>",
        { class: 'alert alert-danger ' }
      );
    } else if (theLanguage == 'pt-BR' || theLanguage == 'pt') {
      var $successMessage = $('<div>', {
        class:
          'alert alert-danger remove-this-on-form-submit alert-danger-form-submit',
        id:
          'formErrors',  
      }).append(
        "<p><i class='fas fa-times'></i>&nbsp;&nbsp;Por favor, preencha todos os campos obrigatórios</p>",
        { class: 'alert alert-danger ' }
      );
    } else if (theLanguage == 'es-MX' || theLanguage == 'es') {
      var $successMessage = $('<div>', {
        class:
          'alert alert-danger remove-this-on-form-submit alert-danger-form-submit',
        id:
          'formErrors',  
      }).append(
        "<p><i class='fas fa-times'></i>&nbsp;&nbsp;Por favor complete todos los campos obligatorios</p>",
        { class: 'alert alert-danger ' }
      );
    } else {
      var $successMessage = $('<div>', {
        class:
          'alert alert-danger remove-this-on-form-submit alert-danger-form-submit',
        id:
          'formErrors',  
      }).append(
        "<p><i class='fas fa-times'></i>&nbsp;&nbsp;Please fill out all mandatory fields</p>",
        { class: 'alert alert-danger ' }
      ); 
    }

    
    $self.after($successMessage);
     
    $('html, body').animate({
        scrollTop: $('#formErrors').offset().top-110
    }, 1000);

    if($("div.g-recaptcha").length > 0) {
      grecaptcha.reset();
    }     
    $('button[type=submit]', $self).prop('disabled', false);
  })
  
  $('form:not(#search-form-press)').on("freeform-render-success",function(event){
    event.preventDefault();
    var $self = $(this);
    var theLanguage = $('html').attr('lang');    
      $('.alert-success-form-submit').remove();
      $('.remove-this-on-form-submit').remove();
      $self[0].reset();
      $('button[type=submit]', $self).prop('disabled', true);
      if (theLanguage == 'de') {     
        var $successMessage = $('<div>', {
          class: 'alert alert-info alert-success-form-submit',
          id:
            'formSuccess',
        }).append(
          "<p><i class='fas fa-check'></i>&nbsp;&nbsp;<span>Vielen Dank!</span> <br> Das Formular wurde erfolgreich abgeschickt und Sie erhalten eine Eingangsbestätigung per E-Mail.<br>Bei Rückfragen wenden Sie sich bitte an die dort angegebenen Kontaktdaten.</p>",
          { class: 'alert alert-success ' }
        );  
      } else if (theLanguage == 'pt-BR' || theLanguage == 'pt') {     
        var $successMessage = $('<div>', {
          class: 'alert alert-info alert-success-form-submit',
          id:
            'formSuccess',
        }).append(
          "<p><i class='fas fa-check'></i>&nbsp;&nbsp;<span>Muito obrigado!</span> <br> Formulário enviado com sucesso.</p>",
          { class: 'alert alert-success ' }
        );  
      } else if (theLanguage == 'es-MX' || theLanguage == 'es') {     
        var $successMessage = $('<div>', {
          class: 'alert alert-info alert-success-form-submit',
          id:
            'formSuccess',
        }).append(
          "<p><i class='fas fa-check'></i>&nbsp;&nbsp;<span>¡Muchas gracias!</span> <br>Formulario enviado con éxito.</p>",
          { class: 'alert alert-success ' }
        );  
      } else {
        var $successMessage = $('<div>', {
          class: 'alert alert-info alert-success-form-submit',
          id:
            'formSuccess',
        }).append(
          "<p><i class='fas fa-check'></i>&nbsp;&nbsp;<span>Thank you!</span> <br> The form has been sent successfully and you will receive a confirmation of receipt by email.</p>",
          { class: 'alert alert-success ' }
        );
      }

      
      $self.after($successMessage);
      
      $('html, body').animate({
        scrollTop: $('#formSuccess').offset().top-110
      }, 1000);
      
      $('#form-submit-button').prop('disabled', true);

      if($("div.g-recaptcha").length > 0) {
        grecaptcha.reset();
      } 
      $('button[type=submit]', $self).prop('disabled', false);
  }) 

  $('.event-anchor-link').click(function() {
    let id = $(this).attr('id');
    $('.' + id).attr('checked', 'checked');
    $('.registration-form')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  });

  // hack to fix nav icons display. This is a known issue, reference - https://github.com/OwlCarousel2/OwlCarousel2/issues/1809
  $('#header1 .owl-nav').removeClass('disabled');
  $('#header1 .owl-nav').click(function() {
    $(this).removeClass('disabled');
  });

  $('#contactForm').submit(function(ev) {



    ev.preventDefault();
    var $self = $(this);
    
    $.post({
      url: '/',
      dataType: 'json',
      data: $(this).serialize(),
      success: function(response) {
        if (response.success) {
          $self[0].reset();

          $('#contactForm')
          .html(
            '<div class="alert alert-success" role="alert">\n' +
              '<i class="fas fa-check"></i>&nbsp;&nbsp;Vielen Dank! Formular erfolgreich abgeschickt.\n' +
              '</div>'
          )
          .fadeIn();
        } else {
          alert("An error occurred. Please try again.");
        }
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        $self[0].reset();
        var $message = $("<div>", {"class": "alert alert-danger"})
            .append('<p>'+XMLHttpRequest.responseText+'</p>', {"class": "alert alert-danger"});
        $self.after($message);
      }
  });
  });

 

  $('#user-range-no-discount, #no-discount-ftp, #no-discount-archive').change(
    function() {
      var $range = String($('#user-range-no-discount').val());
      var $discount = Number($('#discount').val());

      if ($('#no-discount-archive').is(':checked')) {
        var $archive = 2.5;
      } else {
        var $archive = 0.0;
      }

      if ($('#no-discount-ftp').is(':checked')) {
        var $ftp = 0.0;
      } else {
        var $ftp = 0.0;
      }

      if ($range == '1') {
        var $base = 34.5;
      } else if ($range == '2-5') {
        var $base = 54.5;
      } else if ($range == '6-10') {
        var $base = 63.5;
      } else if ($range == '11-20') {
        var $base = 74.5;
      } else if ($range == '21-35') {
        var $base = 114.5;
      } else if ($range == '36-50') {
        var $base = 154.5;
      }

      if ($range == '1') {
        var $base_new = 38.5;
      } else if ($range == '2-5') {
        var $base_new = 60.0;
      } else if ($range == '6-10') {
        var $base_new = 72.0;
      } else if ($range == '11-20') {
        var $base_new = 85.5;
      } else if ($range == '21-35') {
        var $base_new = 126.5;
      } else if ($range == '36-50') {
        var $base_new = 166.5;
      }

      var $total = ($base + $ftp + $archive) * 12;
      var $total_new = ($base_new + $ftp) * 12;
      var $total_new_discount = (($base_new * (1.0 - $discount.toFixed(2) / 100.0)) + $ftp) * 12;

      $('#calculated-cost').html(
        $total
          .toFixed(2)
          .toString()
          .replace('.', ',')
      );
      $('#calculated-cost-new').html(
        $total_new
          .toFixed(2)
          .toString()
          .replace('.', ',')
      );
      $('#calculated-cost-discount').html(
        $total_new_discount
          .toFixed(2)
          .toString()
          .replace('.', ',')
      );

      $('#calculated-cost').addClass('animated flash');
      $('#calculated-cost-new').addClass('animated flash');
      $('#calculated-cost-discount').addClass('animated flash');
      setTimeout(function() {
        $('#calculated-cost').removeClass('animated flash');
        $('#calculated-cost-new').removeClass('animated flash');
        $('#calculated-cost-discount').removeClass('animated flash');
      }, 500);
    

      $('#total').attr(
        'value',
        $total
          .toFixed(2)
          .toString()
          .replace('.', ',')
      );
      $('#total2023').attr(
        'value',
        $total_new
          .toFixed(2)
          .toString()
          .replace('.', ',')
      );

      if ($discount > 0) {
        $('#total2023').attr(
          'value',
          $total_new_discount
            .toFixed(2)
            .toString()
            .replace('.', ',')
        );
      }
    }
  );

  if (window.location.href.indexOf('search') != -1) {
    if (window.location.href.indexOf('search-query') == -1) {
      let params = new URL(document.location).searchParams;
      let query = params.get('q');

      document.getElementById('searchBox').className += ' show';
      document.getElementById('search').placeholder = query;
    }
    if (window.location.href.indexOf('search-query') != -1) {
      let params = new URL(document.location).searchParams;
      let query = params.get('query');
      $('#search-hilfe')
        .val(query)
        .trigger('keyup');
    }
  }

  // begin Login Box

  // Setting target url for login-form
  const currLanguage = document.documentElement.lang;
  if (host == 'metab.test' || host=='metabooks.staging.vlb.de' || host=='metabooks.com' || host=='metabooks.vlb.de') {   
    if (currLanguage == 'pt-BR') {
      host = 'metabooks.com';
      var baseUrl = 'https://' + host + '/app';
    }
    if (currLanguage == 'es-MX') {
      host = 'latam.metabooks.com';
      var baseUrl = 'https://' + host;
    }
  } else {
    var baseUrl = 'https://' + host + '/app';
  }

  
  $('#login-form').attr('action', baseUrl + '/j_spring_security_check');

  $('#reset-button').click(function() {
    var username = $('#reset_username').val();

    // Sends request to reset user's password
    // Sends request to reset user's password
    if (window.location.hostname == 'metab.test' || window.location.hostname=='metabooks.com' || window.location.hostname=='metabooks.testing.vlb.de') {   
      if (document.documentElement.lang == 'pt-BR') {
        $.ajax({
          type: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          url: 'https://' + host + '/app/user/reset/' + username
        });
      }
      if (document.documentElement.lang == 'es-MX') {
        $.ajax({
          type: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          url: 'https://' + host + '/user/reset/' + username
        });
      }
           
    } else {
      $.ajax({
        type: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        url: 'https://' + host + '/app/user/reset/' + username
      });
    }

    $('#exampleModal').modal('hide');
    $('#confirmModal').modal('show');

    return true;
  });

  // Maps the URL to a requestURL and CookieId
  var requestUrl = '';
  var cookieId = '';

  if (location.href.indexOf('staging') > -1) {
    requestUrl = 'staging.vlb.de';
    cookieId = '_pk_id.14.8da7';
  } else {
    requestUrl = 'vlb.de';
    cookieId = '_pk_id.2.9988';
  }

  // Used to check, if the user is already logged in
  if (document.cookie.includes(cookieId)) {
    var xhr = new XMLHttpRequest();

    xhr.open('GET', 'https://' + requestUrl + '/app/product', true);
    xhr.onreadystatechange = function() {
      if (
        this.readyState == 4 &&
        this.status == 404 &&
        !this.responseURL.includes(';')
      ) {
        console.log('Already logged in');
        // Remove the buttons effect on the login box
        // So it doesn't get unfolded when a use is logged in and clicks the "Zum VLB" button
        $('button[data-target="#loginBox"]').text('Zum VLB');
        $('button[data-target="#loginBox"]').click(function() {
          location.href = baseUrl + '/';
        });
        $('button[data-target="#loginBox"]').attr('data-target', '');
      }
    };

    xhr.send(null);
  }
  // END Login Box

  // subscription checkbox
  $('#delivery').change(function() {
    if ($(this).is(':checked')) {
      $('#delivery-fields').html(
        '<h5>Rechnungsanschrift:</h5><div class="row">\n' +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-company">Firmenname&ast;</label>\n' +
          '              <input type="text" class="form-control" id="delivery-company" name="message[Delivery-Firmenname]" placeholder="Muster GmbH" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-customerId">Verkehrs-/Kd.-Nr.&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-customerId" name="message[Delivery-Verkehrs-/Kd.-Nr.]" placeholder="F FM 123" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '        </div>\n' +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-street">Straße/Haus-Nr.&ast;</label>\n' +
          '          <input type="text" class="form-control" id="Delivery-street" name="message[Delivery-Straße/Haus-Nr.]" placeholder="Musterstr./1" required>\n' +
          '        </div>\n' +
          '        <div class="row">\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-zip">PLZ&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-zip" name="message[Delivery-PLZ]" placeholder="60327" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-8">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-city">Ort&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-city" name="message[Delivery-Ort]" placeholder="Frankfurt am Main"\n' +
          '                     required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '        </div>\n' +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-country">Land&ast;</label>\n' +
          '          <input type="text" class="form-control" id="Delivery-country" name="message[Delivery-Land]" placeholder="Deutschland" required>\n' +
          '        </div>\n' +
          '        <div class="row">\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-salutation">Anrede</label>\n' +
          '              <select class="form-control" id="Delivery-salutation" name="message[Delivery-Anrede]">\n' +
          '                <option>Frau</option>\n' +
          '                <option>Herr</option>\n' +
          '                <option>Dr.</option>\n' +
          '                <option>Prof.</option>\n' +
          '              </select>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-fname">Vorname&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-fname" name="message[Delivery-Vorname]" placeholder="Maxx" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-lname">Nachname&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-lname" name="message[Delivery-Nachname]" placeholder="Mustermann" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '        </div>\n' +
          '        <div class="row">\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-title">Titel</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-title" name="message[Delivery-title]" placeholder="Geschäftsführer">\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-prefix">Telefon-Vorwahl&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-prefix" name="message[Delivery-Telefon-Vorwahl]" placeholder="069" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Delivery-phone1">Telefon&ast;</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-phone1" name="message[Delivery-Telefon]" placeholder="xxx xxxx" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '        </div>\n' +
          '        <div class="row">\n' +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Ansprechpartner">Ansprechpartner</label>\n' +
          '              <input type="text" class="form-control" id="Delivery-subject2" name="message[Delivery-Ansprechpartner]"\n' +
          '                 placeholder="Ansprechpartner elektron. Rechnungsversand">\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-6">\n' +
          '            <div class="form-group">\n' +
          '              <label for="E-Mail">E-Mail&ast;</label>\n' +
          '              <input type="text" class="form-control" id="email11" name="message[Delivery-Email]" placeholder="max@mustermann.com" required>\n' +
          '            </div>\n' +
          '          </div>\n' +
          '        </div>\n' +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-subject2">Ansprechpartner</label>\n' +
          '          <input type="text" class="form-control" id="Delivery-subject2" name="message[Delivery-Ansprechpartner]"\n' +
          '                 placeholder="Ansprechpartner elektron. Rechnungsversand">\n' +
          '        </div>\n' +
          '        <div class="form-group">\n' +
          '          <label for="Delivery-email">E-Mail elektron. Rechnungsversand</label>\n' +
          '          <input type="email" class="form-control" id="Delivery-email" name="message[Delivery-E-Mail elektron. Rechnungsversand]" placeholder="maxmustermann@gmx.com"\n' +
          '                 required>\n' +
          '        </div>'
      );
    } else {
      $('#delivery-fields').html('');
    }
  });

  //

  $(
    'input[type=radio][name="message[Die Weiterleitung / Bereitstellung der Bestelldaten erfolgt]"]'
  ).change(function() {
    if ($('#forwarding-ftp').is(':checked')) {
      $('#forwarding-ftp-add').html(
        '<div class="form-group expand-hidden-field"><div class="radio">\n' +
          '            <label>\n' +
          '              <input type="radio" value="auf Server der IBU" name="message[Die Weiterleitung / Bereitstellung Option]">&nbsp;&nbsp;&nbsp;auf Server der IBU\n' +
          '            </label>\n' +
          '          </div>\n' +
          '          <div class="radio">\n' +
          '            <label>\n' +
          '              <input type="radio" value="auf Server des Verlags / Datenempfängers" name="message[Die Weiterleitung / Bereitstellung Option]">&nbsp;&nbsp;&nbsp;auf Server des Verlags / Datenempfängers\n' +
          '            </label>\n' +
          '          </div></div>'
      );
    } else {
      $('#forwarding-ftp-add').html('');
    }

    if ($('#forwarding-email').is(':checked')) {
      $('#forwarding-email-add').html(
        '<div class="form-group expand-hidden-field">\n' +
          '            <label for="E-Mail (falls abweichend von o.g. Adresse)">E-Mail <i>(falls abweichend von o.g. Adresse)</i></label>\n' +
          '            <input type="text" class="form-control" id="E-Mail (falls abweichend von o.g. Adresse)" name="message[E-Mail (falls abweichend von o.g. Adresse)]" placeholder="Max@mustermann.com">\n' +
          '          </div>'
      );
    } else {
      $('#forwarding-email-add').html('');
    }

    if ($('#forwarding-fax').is(':checked')) {
      $('#forwarding-fax-add').html(
        '<div class="form-group expand-hidden-field">\n' +
          '            <label for="Fax (falls abweichend von o.g. Nummer)">Fax <i>(falls abweichend von o.g. Nummer)</i></label>\n' +
          '            <input type="text" class="form-control" id="Fax (falls abweichend von o.g. Nummer)" name="message[Fax (falls abweichend von o.g. Nummer)]" placeholder="123 456 789">\n' +
          '            </div>'
      );
    } else {
      $('#forwarding-fax-add').html('');
    }

    if ($('#forwarding-brief').is(':checked')) {
      $('#forwarding-brief-add').html(
        ' <div class="form-group expand-hidden-field"> <span>Adresse <i>(falls abweichend von o.g. Adresse)</i></span>  \n' +
          '          <div class="row">\n' +
          '          \n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Brief street">Straße/Haus-Nr.</label>\n' +
          '              <input type="text" class="form-control" id="Brief-street" name="message[Brief - Straße/Haus-Nr.]" placeholder="Musterstr./1">\n' +
          '            </div> \n' +
          '          </div>\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Brief zip">PLZ</label>\n' +
          '              <input type="text" class="form-control" id="Brief-zip" name="message[Brief - PLZ]" placeholder="60327">\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          <div class="col-md-4">\n' +
          '            <div class="form-group">\n' +
          '              <label for="Brief city">Ort</label>\n' +
          '              <input type="text" class="form-control" id="Brief-city" name="message[Brief Ort]" placeholder="Frankfurt am Main">\n' +
          '            </div>\n' +
          '          </div>\n' +
          '          </div></div>'
      );
    } else {
      $('#forwarding-brief-add').html('');
    }
  });

  $('input[type=radio][name="message[Clearingzeiten]').change(function() {
    if ($('#clearingzeiten-m').is(':checked')) {
      $('#clearingzeiten-m-add').html(
        '<div class="form-group expand-hidden-field">\n' +
          '                <label for="Uhrzeit">Uhrzeit&ast;</label>\n' +
          '                <input type="text" class="form-control"  name="message[Montag bis Freitag - Uhrzeit]" placeholder="12 Uhr" required="" data-validation="required" >\n' +
          '              </div> '
      );
    } else {
      $('#clearingzeiten-m-add').html('');
    }

    if ($('#clearingzeiten-n').is(':checked')) {
      $('#clearingzeiten-n-add').html(
        '<div class="form-group expand-hidden-field">\n' +
          '                <label for="Uhrzeit2">Uhrzeit&ast;</label>\n' +
          '                <input type="text" class="form-control"  name="message[nach Vereinbarung - Uhrzeit]" placeholder="12 Uhr" required="" data-validation="required">\n' +
          '              </div> '
      );
    } else {
      $('#clearingzeiten-n-add').html('');
    }
  });

  $('input[type=radio][name="message[Bezahlung erfolgt per]').change(
    function() {
      if ($('#bankeinzug').is(':checked')) {
        $('#bankeinzug-add').html(
          '<div class="form-group expand-hidden-field"><div class="row ">\n' +
            '          <div class="col-md-4">\n' +
            '            <div class="form-group">\n' +
            '              <label for="Name der Bank">Name der Bank&ast;</label>\n' +
            '              <input type="text" class="form-control" id="Name der Bank" name="message[Bankeinzug - Name der Bank]" placeholder="Musterbank" required="required">\n' +
            '            </div>\n' +
            '          </div>\n' +
            '          <div class="col-md-4">\n' +
            '            <div class="form-group">\n' +
            '              <label for="BIC">BIC&ast;</label>\n' +
            '              <input type="text" class="form-control" id="BIC" name="message[Bankeinzug - BIC]" placeholder="ABCDEFG1ABC" required="required">\n' +
            '            </div>\n' +
            '          </div>\n' +
            '          <div class="col-md-4">\n' +
            '            <div class="form-group">\n' +
            '              <label for="title">IBAN*</label>\n' +
            '              <input type="text" class="form-control" id="IBAN" name="message[Bankeinzug - IBAN]" placeholder="DE12 1234 1234 1234" required="required">\n' +
            '            </div>\n' +
            '          </div>\n' +
            '        </div></div>  '
        );
      } else {
        $('#bankeinzug-add').html('');
      }
    }
  );

  $('input[type=radio][name="message[Wir verwenden folgende Software]').change(
    function() {
      if ($('#radio-andere').is(':checked')) {
        $('#radio-andere-add').html(
          '<div class="form-group expand-hidden-field"><div class="row">\n' +
            '                <div class="col-md-6">\n' +
            '                  <div class="form-group">\n' +
            '                    <input type="text" class="form-control" name="message[Wir verwenden folgende Software - andere]" placeholder="Muster-Software" data-validation="required required">\n' +
            '                  </div>\n' +
            '                </div>\n' +
            '              </div></div> '
        );
      } else {
        $('#radio-andere-add').html('');
      }
    }
  );

  $(
    'input[type=radio][name="message[Wir sind einem Barsortiment angeschlossen]'
  ).change(function() {
    if ($('#radio-ja').is(':checked')) {
      $('#radio-ja-add').html(
        '<div class="form-group expand-hidden-field">\n' +
          '                <div class="row">\n' +
          '                  <div class="col-md-6">\n' +
          '                    <div class="form-group">\n' +
          '                      <label>Name*</label>\n' +
          '                      <input type="text" class="form-control" name="message[Wir sind einem Barsortiment angeschlossen - Name]" placeholder="Musterbarsortiment" data-validation="required required">\n' +
          '                    </div>\n' +
          '                  </div>\n' +
          '                </div>                      \n' +
          '              </div>'
      );
    } else {
      $('#radio-ja-add').html('');
    }
  });

  $('#fromEmail').change(function() {
    $('#from-email-hidden').val($(this).val());
  });

  // swith view script..

  $(document).find(".switchView a").click(function(e){
    
    console.log($(this).attr('data-switch'))
    e.preventDefault()
    $(document).find(".switchView a").removeClass("activeSwitch")
      if($(this).attr('data-switch') == 'card'){
        $(".tableContent").fadeIn()
        $(".cardContent").css("display","none")
        $(this).addClass("activeSwitch")
      }
      if($(this).attr('data-switch') == 'table'){
        $(".tableContent").css("display","none")
        $(".cardContent").fadeIn().css("display","flex")
        $(this).addClass("activeSwitch")
      }
  })
});


import './hilfe-navigation-updated';
import './buchhandel-scroll';
import './lang-popup';
