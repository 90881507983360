$(document).ready(function(){
  if (window.location.href.indexOf("hilfe") > -1 || window.location.href.indexOf("ajuda") > -1) {
    $.ajax({
      url:'/nav.json',
      type:'GET',
      success:function(response){
          generateNavigation(response.data);
      }
    })
  }
})
$(document).ready(function(){
if (window.location.href.indexOf("ayuda") > -1) {
  $.ajax({
    url:'/es/nav.json',
    type:'GET',
    success:function(response){
        generateNavigation(response.data);
    }
  })
}
})

$(document).ready(function(){
  if (window.location.href.indexOf("help") > -1) {
    $.ajax({
      url:'/en/nav.json',
      type:'GET',
      success:function(response){
          generateNavigation(response.data);
      }
    })
  }
  })

function generateNavigation(navigation){
      var navHTML = '';
      navHTML+= '<ul class="nav-ul-depth-1">'
      navigation.forEach(function(nav){
        navHTML+= getLists(nav);
      });
      navHTML+='</ul>';
      // $('.help-side-navigation').append('<br><br><br><br>');
      $('.help-side-navigation').append(navHTML);
      // console.log(navHTML);
      setHandlersNow($('.help-side-navigation'));
}

function setHandlersNow(navigation){
  // $('div.help-side-navigation span.parent-marker').css('margin-top','0px');
  navigation.find('span').click(function(e){
    e.preventDefault();
    // $(this).closest('li')
    $(this).closest('li').find('ul:first').slideToggle();
    var span = $(this);
    if (span.html() === "+") {
      span.html("-");
    } else {
      span.html("+");
    }
  });
  fix_visibility(navigation);
}

function getLists(navItem){
  var listHTML='';
  if(navItem.children){
    listHTML+= '<li slug="'+navItem.slug+'" class="nav-li-depth-'+navItem.level+'">';
    listHTML += '<div><a slug="'+navItem.slug+'" href="'+navItem.url+'" class="nav-href-depth-'+navItem.level+'">'+navItem.title+'</a><span class="parent-marker parent-marker-'+navItem.level+'">+</span></div>';
    listHTML += '<ul slug="'+navItem.slug+'" class="nav-ul-depth-'+(navItem.level + 1)+'" style = display:none>';
    navItem.children.forEach(function(c){
      if(!c.children){ 
        listHTML += '<li slug="'+c.slug+'" class="nav-li-depth-'+c.level+'"><div><a href="'+c.url+'" slug="'+c.slug+'" class="nav-href-depth-'+c.level+'">'+c.title+'</a></div></li>';
      }
      listHTML += getLists(c);
    })
    listHTML += '</ul>';
    listHTML += '</li>';
  } else {
    if (navItem.level == 1) {
      listHTML += '<li slug="'+navItem.slug+'" class="nav-li-depth-'+navItem.level+'"><div><a href="'+navItem.url+'" slug="'+navItem.slug+'" class="nav-href-depth-'+navItem.level+'">'+navItem.title+'</a></div></li>';
    }
  }
  return listHTML;
}

function fix_visibility(navigation){
  console.log();
  if (window.location.pathname != '/hilfe/') {
    var listPath = window.location.href.split("/hilfe/")[1].split("/");
    listPath.forEach(function(listItem){
      $("[slug="+listItem+"]").css('display','block');
      $("[slug="+listItem+"]").find('> div span').html('-')
    })
    var activeList = listPath.reverse()[0];
    navigation.find("a[slug="+activeList+"]").addClass('active')
    navigation.find("li[slug="+activeList+"]").addClass('active')
  } 
}