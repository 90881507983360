var selectedLang = null;
var currentSite = null;
var enHref = null;
var esHref = null;
var brHref = null;

  

$(document).ready(function(){
  selectedLang = getCurrentLang();
  currentSite = $("#popup-close").data("site");
  getHrefs();
  setLanguageOnPopUp(selectedLang);
  setLangHandlers();
  $("#popup-close").removeClass("close");
})

function setLangHandlers(){
  $("#btn-select-en").on("click", function() {
    setLanguageOnPopUp("EN");
  })
  $("#btn-select-es").on("click", function() {
    setLanguageOnPopUp("ES");
  })
  $("#btn-select-br").on("click", function() {
    setLanguageOnPopUp("BR");
  })
  $("#lang-popup-cancle").on("click", function(){
    cancelSelection();
  })
  $("#popup-close-x").on("click", function(){
    cancelSelection();
  })
}


function cancelSelection(){
  $("#popup-close").addClass("close");
  canceled = true;
  var today = new Date();
  var expire = new Date();
  expire.setTime(today.getTime() + 3600000*24*7); //one week expiring. Just like the cookie from geomate

  let cookieValue = ""
  if (currentSite === "Metabooks PT-BR") {
    cookieValue = "metabooksPtBr"
  } else if (currentSite === "Metabooks ES-MX") {
    cookieValue = "metabooksEsMx"
  } else {
    cookieValue = "metabooksEnDe"
  }
  document.cookie = "GeoMateRedirectOverride=" + encodeURI(cookieValue) + ";expires="+expire.toGMTString() + ";path=/"
}

function setLanguageOnPopUp(lang) {
  $("#btn-select-en").removeClass("active")
  $("#btn-select-es").removeClass("active")
  $("#btn-select-br").removeClass("active")
  selectedLang = lang;
  let text, btnOK, btnCancle, href;
  if (lang === "BR") {
    text = "Escolha o idioma do conteúdo que deseja visualizar";
    btnOK = "Continuar";
    btnCancle = "Cancelar";
    href = brHref;
    $("#btn-select-br").addClass("active")
  } else if (lang === "ES") {
    text = "Selecciona el idioma del contenido que deseas visualizar";
    btnOK = "Continuar";
    btnCancle = "Cancelar";
    href = esHref;
    $("#btn-select-es").addClass("active")
  } else { // English
    text = "Select another language if the preselection does not apply";
    btnOK = "Continue";
    btnCancle = "Cancel";
    href = enHref;
    $("#btn-select-en").addClass("active")
  }
  $("#lang-popup-text").html(text)
  $("#lang-popup-ok").html(btnOK)
  $("#lang-popup-cancle").html(btnCancle)
  $("#lang-popup-ok").prop("href", href)
}

function getCurrentLang(){
  if ($("#btn-select-en").hasClass("active")) return "EN"
  if ($("#btn-select-es").hasClass("active")) return "ES"
  if ($("#btn-select-br").hasClass("active")) return "BR"
}

function getHrefs(){
  enHref = $("#btn-select-en").data("value");
  esHref = $("#btn-select-es").data("value");
  brHref = $("#btn-select-br").data("value");
}